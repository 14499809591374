import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import { 
  Container, 
  Box, 
  Typography, 
  TextField, 
  Button, 
  Alert, 
  CssBaseline,
  ThemeProvider,
  createTheme
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const theme = createTheme();

export default function App() {
  const [supabase, setSupabase] = useState(null);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('info');

  useEffect(() => {
    async function initSupabase() {
      try {
        const response = await fetch('/supabase-config');
        const { url, key } = await response.json();
        const supabaseClient = createClient(url, key);
        setSupabase(supabaseClient);
      } catch (error) {
        console.error('Failed to initialize Supabase client:', error);
        setMessage('Failed to initialize application. Please try again later.');
        setSeverity('error');
      }
    }
    initSupabase();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!supabase) {
      setMessage('Application not ready. Please try again.');
      setSeverity('error');
      return;
    }
    setMessage('');
  
    // Basic validation
    if (!username || !email || !password) {
      setMessage('Please fill in all fields.');
      setSeverity('error');
      return;
    }
  
    if (password.length < 6) {
      setMessage('Password must be at least 6 characters long.');
      setSeverity('error');
      return;
    }
  
    try {
      console.log('Attempting to register with:', { email, username });
      
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            username: username
          }
        }
      });
  
      if (error) throw error;
  
      console.log('Registration response:', data);
  
      if (data.user) {
        setMessage(`Registration successful! Please check your email to confirm your account.`);
        setSeverity('success');
      } else {
        setMessage('Registration failed. Please try again.');
        setSeverity('error');
      }
    } catch (error) {
      console.error('Registration error:', error);
      if (error.response) {
        try {
          const errorBody = await error.response.json();
          console.error('Error response body:', errorBody);
          setMessage(`Registration failed: ${errorBody.error_description || errorBody.msg || error.message}`);
        } catch (e) {
          setMessage(`Registration failed: ${error.message}`);
        }
      } else {
        setMessage(`Registration failed: ${error.message}`);
      }
      setSeverity('error');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <LockOutlinedIcon sx={{ m: 1, bgcolor: 'secondary.main', p: 1, borderRadius: '50%', color: 'white' }} />
          <Typography component="h1" variant="h5">
            Register for Whoo.is
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Register
            </Button>
          </Box>
        </Box>
        {message && (
          <Alert severity={severity} sx={{ mt: 2 }}>
            {message}
          </Alert>
        )}
      </Container>
    </ThemeProvider>
  );
}